<template>
  <div class="child-container cont">
    <h1 style="text-align: left;" class="cont-title"><span style="font-size: 72px; font-family: PingFang&nbsp;SC, Microsoft&nbsp;YaHei, sans-serif; color: #ffffff;">Contact</span></h1>
    <div class="cont-content">
      <p class="p-text">联系我们</p>
      <p class="p-line"></p>
      <p class="p-text">E-mail:bsds@bsds100.com</p>
      <p class="p-line"></p>
      <p class="p-text">Tel: 025-83726176</p>
      <p class="p-text">Fax:025-83726176</p>
      <p class="p-line"></p>
      <p class="p-text">江苏省南京市雨花台区民智路12号喜马拉雅中心C座315</p>
      <p class="p-line"></p>
      <p class="p-text">315, build C, Himalayan center, No. 12, Minzhi Road, Yuhuatai District, Nanjing, Jiangsu</p>
      <p class="p-line p-line-long"></p>

      <div id="map" class="map"></div>
      <div id="map-info" class="map-info"></div>
    </div>
  </div>
</template>

<script>
import getMap from "@/utils/Map.js";

export default {
  name: "About",
  data() {
    return {};
  },
  methods: {
    handleSelect() {}
  },
  mounted() {
    if (window.onloadMap) {
      window.initialize && window.initialize();
    } else {
      getMap();
    }
  }
};
</script>

<style lang="less" scoped>
.cont-title {
  margin: 60px 0 80px 0;
  padding: 0 0 0 26px;
  background-color: #000000;
  width: 434px;
  font-family: PingFang SC, Microsoft YaHei, sans-serif;
  font-weight: 500;
}

.cont-content {
  .p-text {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: rgba(0,0,0,0.85);
    line-height: 3;
    + .p-text {
      margin-top: -15px;
    }
  }
  .p-line {
    height: 3px;
    width: 20px;
    background: #444444;
  }
  .p-line-long {
    width: 100%;
  }
}

.map {
  width: 100%;
  height: 60vh;
  margin-top: 150px;
}
.map-info {
  opacity: 0;
}
</style>
