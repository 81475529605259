/* eslint-disable */
export default function getMap () {
  let script = document.createElement("script");
  script.src = "//api.map.baidu.com/api?v=3.0&ak=E8kvDYS5tCiWn23ZGgG4C8iZocVYvTUD&callback=initialize";
  document.body.appendChild(script);
  window.initialize = function () {
    window.onloadMap = true;
    var map = new BMap.Map('map');
    var point = new BMap.Point(118.810908,31.970518);
    map.centerAndZoom(point, 20);
    map.enableScrollWheelZoom(true);
    var sContent = "江苏省南京市雨花台区民智路12号喜马拉雅中心C座315";
    var infoWindow = new BMap.InfoWindow(sContent); // 创建信息窗口对象
    map.openInfoWindow(infoWindow, point); //开启信息窗口
    document.getElementById("map-info").innerHTML = infoWindow.getContent();
  }
}
